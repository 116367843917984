<template>
  <div>
    <v-app-bar v-if="isLoggedIn" app color="#12133a" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-img src="@/assets/pf-logo.svg" max-width="55"> </v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!--Routes to login form-->
      <v-btn v-if="!isLoggedIn" to="/login" text class="mr-2">
        Login
        <v-icon right>mdi-account</v-icon>
      </v-btn>
      <!--Routes to account registration form-->
      <v-btn
        v-if="!isLoggedIn"
        to="/register"
        elevation="0"
        class="sign_up_btn"
      >
        Sign Up
      </v-btn>
      <v-btn v-if="isLoggedIn" v-on:click="userLogout" elevation="0" text>
        Log Out
      </v-btn>
    </v-app-bar>

    <!--Side Navigation menu-->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      width="260px"
      app
      fixed
      temporary
      bottom
      color="#141442"
      dark
      style="position: fixed; top: 0; left: 0; overflow-y: scroll"
    >
      <v-list flat>
        <!--Dashboard-->
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon left small>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <!--Admin-->
        <v-list-item
          link
          to="/admin"
          v-show="this.user_roles_list.flat().includes('super_admin')"
        >
          <v-list-item-icon>
            <v-icon left small>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item>

        <!--Company Profile-->
        <v-list-item
          link
          to="/company-profile/NVmvKiV1F7os4LCRljSC"
          v-show="this.user_roles_list.flat().includes('super_admin')"
        >
          <v-list-item-icon>
            <v-icon left small>mdi-store</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Company Profile</v-list-item-title>
        </v-list-item>

        <!--Customers-->
        <v-list-item
          link
          to="/customers"
          v-show="this.user_roles_list.flat().includes('customers_read')"
        >
          <v-list-item-icon>
            <v-icon left small>mdi-account-supervisor</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Customers</v-list-item-title>
        </v-list-item>

        <!-- CRM -->
        <v-list-item
          link
          to="/crm-dashboard"
          v-show="this.user_roles_list.flat().includes('crm_read')"
        >
          <v-list-item-icon>
            <v-icon left small>mdi-message</v-icon>
          </v-list-item-icon>
          <v-list-item-title>CRM</v-list-item-title>
        </v-list-item>

        <!--Sales-->
        <v-list-item
          link
          to="/sales-orders"
          v-show="this.user_roles_list.flat().includes('sales_read')"
        >
          <v-list-item-icon>
            <v-icon left small>mdi-cash-register</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sales</v-list-item-title>
        </v-list-item>
        

        <!-- Retail -->
        <v-list-group
          prepend-icon="mdi-store"
          no-action
          v-show="this.user_roles_list.flat().includes('ecommerce_read')"
        >
          <template v-slot:activator>
            <v-list-item-title>Retail</v-list-item-title>
          </template>
          <v-list-item link to="/branches">
            <v-list-item-content>
              <v-list-item-title>Branches</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/branch-stock-transfers">
            <v-list-item-content>
              <v-list-item-title>Stock Transfers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--Inventory-->
        <v-list-group
          prepend-icon="mdi-package-variant"
          no-action
          v-show="this.user_roles_list.flat().includes('products_read')"
        >
          <template v-slot:activator>
            <v-list-item-title>Inventory</v-list-item-title>
          </template>
          <v-list-item link to="/inventory">
            <v-list-item-content>
              <v-list-item-title>Stock Levels</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/stock-entries">
            <v-list-item-content>
              <v-list-item-title>Stock Entries</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/branch-stock-transfers">
            <v-list-item-content>
              <v-list-item-title>Branch Stock Transfers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--Suppliers-->
        <v-list-item
          link
          to="/suppliers"
          v-show="this.user_roles_list.flat().includes('suppliers_read')"
        >
          <v-list-item-icon>
            <v-icon left small>mdi-account-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Suppliers</v-list-item-title>
        </v-list-item>

        <!--Manufacturing-->
        <v-list-group
          prepend-icon="mdi-hammer-wrench"
          no-action
          v-show="this.user_roles_list.flat().includes('work_orders_read')"
        >
          <!--Dropdown list-->
          <template v-slot:activator>
            <v-list-item-title>Manufacturing</v-list-item-title>
          </template>

          <v-list-item
            link
            to="/production-planning"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Production Planning</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/order-fulfillment"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Order Fulfillment</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/work-orders"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Work Orders</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            to="/production-instructions"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Production Instructions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            to="/materials"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Materials</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            to="/parts-assemblies"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Parts & Assemblies</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            to="/plants-work-centers"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Work Centers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            to="/machines"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Machines</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            to="/units-of-measurement"
            v-show="this.user_roles_list.flat().includes('work_orders_read')"
          >
            <v-list-item-content>
              <v-list-item-title>Units of Measurement</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--Deliveries-->
        <v-list-item
          link
          to="/deliveries"
          v-show="this.user_roles_list.flat().includes('deliveries_read')"
        >
          <v-list-item-icon>
            <v-icon left small>mdi-truck</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Deliveries</v-list-item-title>
        </v-list-item>

        <!--Banking-->
        <v-list-group
          prepend-icon="mdi-bank"
          no-action
          v-show="this.user_roles_list.flat().includes('banking_read')"
        >
          <!--Dropdown list-->
          <template v-slot:activator>
            <v-list-item-title>Banking</v-list-item-title>
          </template>

          <v-list-item link to="/bank-accounts">
            <v-list-item-content>
              <v-list-item-title>Bank Accounts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/bank-statements">
            <v-list-item-content>
              <v-list-item-title>Bank Statements</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--Accounting-->
        <v-list-group
          prepend-icon="mdi-calculator-variant"
          no-action
          v-show="this.user_roles_list.flat().includes('accounting_read')"
        >
          <!--Dropdown list-->
          <template v-slot:activator>
            <v-list-item-title>Accounting</v-list-item-title>
          </template>
          <v-list-item
            v-for="el in [
              { name: 'Invoices', to: '/invoices' },
              { name: 'Credit Notes', to: '/customer-credit-notes' },
              { name: 'Chart of Accounts', to: '/chart-of-accounts' },
              { name: 'Journal Entries', to: '/journal-entries' },
              { name: 'Journal Reports', to: '/journal-reports' },
              { name: 'Financial Statements', to: '/financial-statements' },
            ]"
            :key="el.name"
            link
            :to="el.to"
          >
            <v-list-item-content>
              <v-list-item-title>{{ el.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--Products-->
        <v-list-group
          prepend-icon="mdi-cart"
          no-action
          v-show="this.user_roles_list.flat().includes('products_read')"
        >
          <template v-slot:activator>
            <v-list-item-title>Products</v-list-item-title>
          </template>
          <v-list-item
            v-for="el in [
              { name: 'Products', to: '/products' },
              { name: 'Variants', to: '/variants' },
            ]"
            :key="el.name"
            link
            :to="el.to"
          >
            <v-list-item-content>
              <v-list-item-title>{{ el.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--Ecommerce-->
        <v-list-group
          prepend-icon="mdi-store"
          no-action
          v-show="this.user_roles_list.flat().includes('ecommerce_read')"
        >
          <template v-slot:activator>
            <v-list-item-title>Ecommerce</v-list-item-title>
          </template>
          <v-list-item link to="/ecommerce/products">
            <v-list-item-content>
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--Employees-->
        <v-list-item
          link
          to="/employees"
          v-show="this.user_roles_list.flat().includes('employees_read')"
        >
          <v-list-item-icon>
            <v-icon left small>mdi-human-male-male</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Employees</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2 my-3 mx-7">
          <v-btn
            v-if="isLoggedIn"
            v-on:click="userLogout"
            block
            color="secondary"
            light
          >
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!--end of SideMenu-->
  </div>
</template>

<script>
import firebase from "firebase";
import db from "../components/firebaseInit";

export default {
  name: "TheTopNav",
  data() {
    return {
      //Used to hide or display certain components based on login status
      isLoggedIn: false,
      currentUser: false,
      canReadCustomers: false,
      user_roles_list: [],
      //Used to trigger side navigation menu
      drawer: null,
      //Current user display info
      user_display_email: null,
    };
  },
  created() {
    //Tests to see if user is logged in
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true;
      this.currentUser = firebase.auth().currentUser.user_email;
    }
    //Verification
    //Get the user id of the current user
    var user_id = firebase.auth().currentUser.uid;
    // Get current user's roles
    db.collection("users")
      .where("uid", "==", user_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user_roles = doc.data().roles;
          // Send user roles to array
          this.user_roles_list.push(user_roles);
        });
      });
  },
  methods: {
    //Logs out the current user
    userLogout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/login");
        });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.sign_up_btn {
  color: #2e3f4f !important;
  background-color: #e7e7f7 !important;
}
a.account-route {
  text-decoration: none;
}
.bigger-text {
  font-size: 1.6rem;
}
.white-text {
  color: #fff;
}
</style>
