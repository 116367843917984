const claim = {
    read: "accounting_read",
    write: "accounting_write"
}
export default [
    //ACCOUNTING
    {
        //Invoices
        path: '/invoices',
        name: 'invoices',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/Invoices.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Credit Notes
        path: '/customer-credit-notes',
        name: 'customer-credit-notes',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/CustomerCreditNotes.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Chart of Accounts
        path: '/chart-of-accounts',
        name: 'chart-of-accounts',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/ChartOfAccounts.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Journal Entries
        path: '/journal-entries',
        name: 'journal-entries',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/JournalEntries.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Journal Reports
        path: '/journal-reports',
        name: 'journal-reports',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/JournalReports.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Edit Journal entry
        path: '/edit-journal-entry/:transaction_id',
        name: 'edit-journal-entry',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/EditJournalEntry.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Financial Statements
        path: '/financial-statements',
        name: 'financial-statements',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/FinancialStatements.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Income Statement
        path: '/income-statement',
        name: 'income-statement',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/IncomeStatement.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Balance Sheet
        path: '/balance-sheet',
        name: 'balance-sheet',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/BalanceSheet.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Accounting Fix
        path: '/accounting-fix',
        name: 'accounting-fix',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/AccountingFix.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Sales Report
        path: '/sales-report',
        name: 'sales-report',
        component: () => import(/* webpackChunkName: "accounting" */'../Accounting/views/SalesReport.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]