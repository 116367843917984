//MAIN ROUTER FOR ENTIRE SYSTEM

import Vue from 'vue'
import Router from 'vue-router';
import firebase from 'firebase'

// Import Module routes
import customerRoutes from "../Modules/Customers/router"
import adminRoutes from "../Modules/Admin/router"
import salesRoutes from "../Modules/SalesOrders/router"
import productsRoutes from "../Modules/Products/router"
import employeeRoutes from "../Modules/Employees/router"
import supplierRoutes from "../Modules/Suppliers/router"
import manufacturingRoutes from "../Modules/Manufacturing/router"
import bankingRoutes from "../Modules/Banking/router"
import deliveriesRoutes from "../Modules/Deliveries/router"
import accountingRoutes from "../Modules/Accounting/router"
import reportRoutes from "../Modules/Reports/router"
import ecommerceRoutes from "../Modules/Ecommerce/router"
import branchRoutes from "../Modules/Retail/router"
import inventoryRoutes from "../Modules/Inventory/router"
import OrderFulfillmentRoutes from "../Modules/OrderFulfillment/router"
import CrmRoutes from "../Modules/CRM/router"
import productionInstructionRoutes from "../Modules/ProductionInstructions/router"

Vue.use(Router);
const baseRoutes = []
const routes = baseRoutes.concat(
  adminRoutes,
  customerRoutes,
  salesRoutes,
  productsRoutes,
  employeeRoutes,
  supplierRoutes,
  manufacturingRoutes,
  bankingRoutes,
  deliveriesRoutes,
  accountingRoutes,
  reportRoutes,
  ecommerceRoutes,
  branchRoutes,
  inventoryRoutes,
  OrderFulfillmentRoutes,
  CrmRoutes,
  productionInstructionRoutes,
)
const baseRouter = new Router({ routes, })

// Nav Guard
baseRouter.beforeEach((to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if user has correct permissions to access route

    // Check if NO logged user
    if (!firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    } else next();

  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if NO logged user
    if (firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      });
    }
    // Proceed to route
    else next();
  }
  // Proceed to route
  else next();
});
export default baseRouter;

