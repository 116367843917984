const claim = {
    read: "products_read",
    write: "products_write"
}
export default [
    //PRODUCT ROUTES
    {
        //Products table
        path: '/products',
        name: 'products',
        component: () => import(/* webpackChunkName: "products" */'../Products/views/Products'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Product
        path: '/new-product',
        name: 'new-product',
        component: () => import(/* webpackChunkName: "products" */'../Products/views/NewProductForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit product
        path: '/edit-product/:product_id',
        name: 'edit-product',
        component: () => import(/* webpackChunkName: "products" */'../Products/views/NewProductForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Categories Table
        path: '/categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "products" */'../Products/views/Categories.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New category
        path: '/new-category',
        name: 'new-category',
        component: () => import(/* webpackChunkName: "products" */'../Products/views/CategoryForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Category
        path: '/edit-category/:category_id',
        name: 'edit-category',
        component: () => import(/* webpackChunkName: "products" */'../Products/views/CategoryForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        // Variants
        path: '/variants',
        name: 'variants',
        component: () => import(/* webpackChunkName: "variants" */'../Products/views/Variants.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        // New Variant Item
        path: '/new-variant-set',
        name: 'new-variant-set',
        component: () => import(/* webpackChunkName: "variants" */'../Products/components/Forms/VariantSetForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Variant Item
        path: '/edit-variant-set/:variant_set_id',
        name: 'edit-variant-set',
        component: () => import(/* webpackChunkName: "variants" */'../Products/components/Forms/VariantSetForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
]