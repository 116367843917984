const claim = {
    read: "order_fulfillment_read",
    write: "order_fulfillment_write"
}
export default [
    //INVENTORY ROUTES
    {
        path: '/order-fulfillment',
        name: 'order-fulfillment',
        component: () => import(/* webpackChunkName: "order-fulfillment" */'./views/OrderFulfillmentDashboard.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        path: '/fulfillment-calendar',
        name: 'fulfillment-calendar',
        component: () => import(/* webpackChunkName: "order-fulfillment" */'./views/FulfillmentCalendar.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]