import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueCurrencyInput from 'vue-currency-input' //format currency
import VueSignature from "vue-signature-pad"
import firebase from 'firebase'
import './components/firebaseInit'
import VuePapaParse from 'vue-papa-parse'

Vue.config.productionTip = false

//Formats Currency in input fields
const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'ZAR' }
}
Vue.use(VueCurrencyInput, pluginOptions)
Vue.use(VueSignature)
Vue.use(VuePapaParse)

//Formats currency fin table cells
Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ZAR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});


///Firebase authentification to ensure that user stays logged in if browser is refreshed
let app;

firebase.auth().onAuthStateChanged(user => {
  //Get the custom claims for each user
  if (user != null) {
    user.getIdTokenResult(true).then(getIdTokenResult => {
      console.log(getIdTokenResult.claims + "Testing")
    })
  } else {
    console.log(`user not found`)
  }

  if (!app) {
    app = new Vue({
      vuetify,
      router,
      render: h => h(App),
    },
    ).$mount('#app')
  }
})

