// EventBus/index.js

import Vue from 'vue';

export const ACTIONS = {
  SNACKBAR: 'snackbar',
};

const EventBus = new Vue();

export default EventBus;