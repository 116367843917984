const claim = {
    read: "sales_read",
    write: "sales_write"
}
export default [
    //REPORT ROUTES
    {
        //Sales Per Product
        path: '/sales-per-product',
        name: 'sales-per-product',
        component: () => import(/* webpackChunkName: "reports" */'../Reports/views/SalesPerProductReport.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
]