const claim = {
    read: "branches_read",
    write: "branches_write"
}
export default [
    //BRANCH ROUTES
    {
        path: '/branches',
        name: 'branches',
        component: () => import(/* webpackChunkName: "branches" */'./views/Branches.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Branch
        path: '/new-branch',
        name: 'new-branch',
        component: () => import(/* webpackChunkName: "branches" */'./views/BranchForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Branch
        path: '/edit-branch/:branch_id',
        name: 'edit-branch',
        component: () => import(/* webpackChunkName: "branches" */'./views/BranchForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //View Branch
        path: '/view-branch/:branch_id',
        name: 'view-branch',
        component: () => import(/* webpackChunkName: "branches" */'./views/ViewBranch.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]