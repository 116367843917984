const claim = {
    read: "ecommerce_read",
    write: "ecommerce_write"
}
export default [
    {
        // Products && Categories
        path: '/ecommerce/products',
        name: 'ecommerce-products-dashboard',
        component: () => import(/* webpackChunkName: "ecommerce" */'../Ecommerce/views/EcommerceProductDashboard.vue'),
        params: true,
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        // Edit Category
        path: '/ecommerce/categories/:id',
        name: 'ecommerce-edit-category',
        component: () => import(/* webpackChunkName: "ecommerce" */'../Ecommerce/views/EcommerceCategory.vue'),
        params: true,
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        // Edit Product
        path: '/ecommerce/products/:id',
        name: 'ecommerce-edit-product',
        component: () => import(/* webpackChunkName: "ecommerce" */'../Ecommerce/views/EcommerceProduct.vue'),
        params: true,
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]