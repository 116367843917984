const claim = {
    read: "customers_read",
    write: "customers_write"
}
export default [
    //CUSTOMER ROUTES
    {
        //Customer individuals page
        path: '/customers-companies',
        name: 'customers-companies',
        component: () => import(/* webpackChunkName: "customers" */'../Customers/views/CustomerCompanyTable.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Customer companies page
        path: '/customers',
        name: 'customers',
        component: () => import(/* webpackChunkName: "customers" */'../Customers/views/Customers.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Customer (Individual)
        path: '/new-customer-individual',
        name: 'new-customer-individual',
        component: () => import(/* webpackChunkName: "customers" */'../Customers/views/CustomerIndividualForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //New Customer (Company)
        path: '/new-customer-company',
        name: 'new-customer-company',
        component: () => import(/* webpackChunkName: "customers" */'../Customers/views/CustomerCompanyForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Customer (Individual)
        path: '/edit-customer/:customer_id',
        name: 'edit-customer',
        component: () => import(/* webpackChunkName: "customers" */'../Customers/views/CustomerIndividualForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Customer (company)
        path: '/edit-customer-company/:customer_id',
        name: 'edit-customer-company',
        component: () => import(/* webpackChunkName: "customers" */'../Customers/views/CustomerCompanyForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //View Customers (individuals)
        path: '/view-customer/:customer_id',
        name: 'view-customer',
        component: () => import(/* webpackChunkName: "customers" */'../Customers/views/ViewCustomer.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //View Customers (Company)
        path: '/view-customer-company/:customer_id',
        name: 'view-customer-company',
        component: () => import(/* webpackChunkName: "customers" */'../Customers/views/ViewCustomerCompany.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]