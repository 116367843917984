<template>
  <v-app>
    <!--Top Navigation bar-->
    <TheTopNav :key="$route.fullPath" />

    <!--Component views are dynamically inserted here-->
    <v-main>
      <router-view />
    </v-main>

    <!--Global snackbar notifications-->
    <!--relies on EventBus/index and globalActions/index-->
    <!--snackbar messages are called on firebase actions-->
    <v-snackbar v-model="snackbar" timeout="4000" color="#33cc99" top>{{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon right v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!--Bottom navigation bar-->
    <TheBottomNav :key="$route.fullPath" />
  </v-app>
</template>
    

<script>
import TheTopNav from "./components/TheTopNav";
import TheBottomNav from "./components/TheBottomNav";
import EventBus, { ACTIONS } from "./EventBus/index";
export default {
  name: "App",
  components: { TheTopNav, TheBottomNav },

  data: () => ({
    snackbar: false,
    snackbarMessage: "",
  }),
  mounted() {
    EventBus.$on(ACTIONS.SNACKBAR, (message) => {
      this.snackbarMessage = message;
      this.snackbar = true;
    });
  },
};
</script>