const claim = {
    read: "suppliers_read",
    write: "suppliers_write"
}
export default [
    //SUPPLIERS
    {
        //Suppliers Table
        path: '/suppliers',
        name: 'suppliers',
        component: () => import(/* webpackChunkName: "suppliers" */'../Suppliers/views/Suppliers.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]