const claim = {
    read: "banking_read",
    write: "banking_write"
}
export default [
    //BANKING
    {
        //Bank accounts Table
        path: '/bank-accounts',
        name: 'bank-accounts',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/BankAccounts.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New bank account
        path: '/new-bank-account',
        name: 'new-bank-account',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/NewBankAccount.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit bank account
        path: '/edit-bank-account/:bank_account_id',
        name: 'edit-bank-account',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/EditBankAccount.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //New credit card
        path: '/new-credit-card',
        name: 'new-credit-card',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/NewCreditCard.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //New petty cash account
        path: '/new-petty-cash-account',
        name: 'new-petty-cash-account',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/PettyCashForm.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit petty cash account
        path: '/edit-petty-cash/:bank_account_id',
        name: 'edit-petty-cash',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/PettyCashForm.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //New speed point
        path: '/new-speed-point',
        name: 'new-speed-point',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/NewSpeedPoint.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit speed point
        path: '/edit-speed-point/:bank_account_id',
        name: 'edit-speed-point',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/EditSpeedPoint.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Bank Statements
        path: '/bank-statements',
        name: 'bank-statements',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/BankStatements.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Bank Transaction
        path: '/new-bank-transaction/:transaction_type',
        name: 'new-bank-transaction',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/NewBankTransaction.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit bank transaction
        path: '/edit-bank-transaction/:transaction_id',
        name: 'edit-bank-transaction',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/EditBankTransaction.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //New Inter Account Transfer
        path: '/new-inter-account-transfer',
        name: 'new-inter-account-transfer',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/NewInterAccountTransfer.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //View bank transaction
        path: '/view-bank-transaction/:transaction_id',
        name: 'view-bank-transaction',
        component: () => import(/* webpackChunkName: "bank-accounts" */'../Banking/views/ViewBankTransaction.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]