const claim = {
    read: "deliveries_read",
    write: "deliveries_write"
}
export default [
    //DELIVERIES
    {
        //Deliveries Table
        path: '/deliveries',
        name: 'deliveries',
        component: () => import(/* webpackChunkName: "deliveries" */'../Deliveries/views/Deliveries.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Delivery Note
        path: '/new-delivery-note/:order_id',
        name: 'new-delivery-note',
        component: () => import(/* webpackChunkName: "deliveries" */'../Deliveries/views/NewDeliveryNote.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Delivery Note
        path: '/edit-delivery-note/:delivery_id',
        name: 'edit-delivery-note',
        component: () => import(/* webpackChunkName: "deliveries" */'../Deliveries/views/EditDeliveryNote.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //View Delivery Note
        path: '/view-delivery-note/:delivery_id',
        name: 'view-delivery-note',
        component: () => import(/* webpackChunkName: "deliveries" */'../Deliveries/views/ViewDeliveryNote.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Delivery Schedule
        path: '/delivery-schedule',
        name: 'delivery-schedule',
        component: () => import(/* webpackChunkName: "deliveries" */'../Deliveries/views/DeliverySchedule.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]