export default [
    //ADMIN ROUTES
    {
        //Dashboard
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "admin" */ '../../views/Home.vue'),
        meta: { requiresAuth: true }
    },
    {
        //Login page
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "admin" */ '../Admin/views/Login.vue'),
        //Applies a guard so this is accessible to certain people
        meta: { requiresGuest: true }
    },
    {
        //Registration form
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "admin" */ '../Admin/views/Register.vue'),
        //Applies a guard
        meta: { requiresGuest: true }
    },
    {
        //Welcome page
        path: '/welcome',
        name: 'welcome',
        component: () => import(/* webpackChunkName: "admin" */ '../Admin/views/Welcome.vue'),
        //Applies a guard
        meta: { requiresAuth: true }
    },
    {
        //No Permissions page
        path: '/no-permission',
        name: 'no-permission',
        component: () => import(/* webpackChunkName: "admin" */ '../Admin/views/NoPermission.vue'),
        //Applies a guard
        meta: { requiresAuth: true }
    },
    {
        //Admin
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '../Admin/views/Admin.vue'),
        //Applies a guard
        meta: { requiresAuth: true, customClaim: "admin_all" },
    },
    {
        //Edit User
        path: '/edit-user/:uid',
        name: 'edit-user',
        component: () => import(/* webpackChunkName: "admin" */ '../Admin/views/EditUser.vue'),
        params: true,
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: "admin_all" }
    },
    //COMPANY PROFILE ROUTES
    {
        path: '/company-profile/:company_id',
        name: 'company-profile',
        component: () => import(/* webpackChunkName: "company-profile" */'../CompanyProfile/views/CompanyProfile.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: 'admin_all' }
    },
]