const claim = {
    read: "inventory_read",
    write: "inventory_write"
}
export default [
    //INVENTORY ROUTES
    {
        path: '/inventory',
        name: 'inventory',
        component: () => import(/* webpackChunkName: "inventory" */'./views/InventoryDashboard.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },

    //STOCK ENTRY ROUTES
    {
        path: '/stock-entries',
        name: 'stock-entries',
        component: () => import(/* webpackChunkName: "inventory" */'./views/StockEntries.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        path: '/new-stock-entry',
        name: 'new-stock-entry',
        component: () => import(/* webpackChunkName: "inventory" */'./views/StockEntryForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    // BRANCH STOCK TRANSFERS
    {
        path: '/branch-stock-transfers',
        name: 'branch-stock-transfers',
        component: () => import(/* webpackChunkName: "inventory" */'./views/BranchStockTransfersDashboard.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        path: '/new-branch-stock-transfer',
        name: 'new-branch-stock-transfer',
        component: () => import(/* webpackChunkName: "inventory" */'./views/BranchStockTransferForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //View Branch
        path: '/edit-branch-stock-transfer/:transfer_id',
        name: 'edit-branch-stock-transfer',
        component: () => import(/* webpackChunkName: "inventory" */'./views/BranchStockTransferForm.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]