const claim = {
    read: "crm_read",
    write: "crm_write"
}
export default [
    //CRM ROUTES
    {
        path: '/crm-dashboard',
        name: 'crm-dashboard',
        component: () => import(/* webpackChunkName: "crm" */'./views/CrmDashboard.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]