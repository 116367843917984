const claim = {
    read: "sales_read",
    write: "sales_write"
}
export default [
    //SALES ORDER ROUTES
    {
        //Sales Orders Table
        path: '/sales-orders',
        name: 'sales-orders',
        component: () => import(/* webpackChunkName: "sales" */'../SalesOrders/views/SalesOrders.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Sales Orders
        path: '/new-sales-order',
        name: 'new-sales-order',
        component: () => import(/* webpackChunkName: "sales" */'../SalesOrders/views/NewSalesOrder.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Sales Order
        path: '/edit-order/:order_id',
        name: 'edit-order',
        component: () => import(/* webpackChunkName: "sales" */'../SalesOrders/views/EditSalesOrder.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //View Sales Order
        path: '/view-order/:order_id',
        name: 'view-order',
        component: () => import(/* webpackChunkName: "sales" */'../SalesOrders/views/ViewSalesOrder.vue'),
        params: true,
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]