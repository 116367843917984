const claim = {
    read: "work_orders_read",
    write: "work_orders_write"
}
export default [
    //MANUFACTURING

    // Production planning
    {
        path: '/production-planning',
        name: 'production-planning',
        component: () => import(/* webpackChunkName: "production-planning" */'../Manufacturing/views/ProductionPlans.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        path: '/new-production-plan',
        name: 'new-production-plan',
        component: () => import(/* webpackChunkName: "production-planning" */'../Manufacturing/views/NewProductionPlan.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        path: '/production-plan-scanner',
        name: 'production-plan-scanner',
        component: () => import(/* webpackChunkName: "production-planning" */'../Manufacturing/views/ProductionPlanScanner.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    //MATERIALS
    //Materials Table
    {
        path: '/materials',
        name: 'materials',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/Materials.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    //New material item
    {
        path: '/new-material-item',
        name: 'new-material-item',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/NewMaterialItem.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //New material categeory
    {
        path: '/new-material-category',
        name: 'new-material-category',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/NewMaterialCategory.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //New material type
    {
        path: '/new-material-type',
        name: 'new-material-type',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/NewMaterialType.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Material Item
        path: '/edit-material-item/:material_item_id',
        name: 'edit-material-item',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/EditMaterialItem.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //View Material Item
        path: '/view-material-item/:material_item_id',
        name: 'view-material-item',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/ViewMaterialItem.vue'),
        params: true,
        //Requires a login to access
        meta: { requiresAuth: false, customClaim: claim.read }
    },
    {
        //Edit Material Category
        path: '/edit-material-category/:material_category_id',
        name: 'edit-material-category',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/EditMaterialCategory.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Material Type
        path: '/edit-material-type/:material_type_id',
        name: 'edit-material-type',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/EditMaterialType.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },

    //PARTS & ASSEMBLIES
    {
        path: '/parts-assemblies',
        name: 'parts-assemblies',
        component: () => import(/* webpackChunkName: "parts-assemblies" */'../Manufacturing/views/PartsAndAssemblies.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    //New Part
    {
        path: '/new-part',
        name: '/new-part',
        component: () => import(/* webpackChunkName: "parts-assemblies" */'../Manufacturing/views/NewPart.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Part
        path: '/edit-part/:part_id',
        name: 'edit-part',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/NewPart.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //View Part
        path: '/view-part/:part_id',
        name: 'view-part',
        component: () => import(/* webpackChunkName: "parts-assemblies" */'../Manufacturing/views/ViewPart.vue'),
        params: true,
        //Requires a login to access
        meta: { requiresAuth: false, customClaim: claim.read }
    },

    //New Assembly
    {
        path: '/new-assembly',
        name: '/new-assembly',
        component: () => import(/* webpackChunkName: "parts-assemblies" */'../Manufacturing/views/NewAssembly.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Assembly
        path: '/edit-assembly/:assembly_id',
        name: 'edit-assembly',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/NewAssembly.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },

    //WORK CENTERS
    {
        path: '/plants-work-centers',
        name: '/plants-work-centers',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/WorkCenters.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    //New Plant
    {
        path: '/new-plant',
        name: '/new-plant',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/NewPlant.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //Edit Plant
    {
        path: '/edit-plant/:plant_id',
        name: 'edit-plant',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/EditPlant.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //New Work Center Group
    {
        path: '/new-work-center-group',
        name: '/new-work-center-group',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/NewWorkCenterGroup.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //Edit Work Center Group
    {
        path: '/edit-work-center-group/:work_center_group_id',
        name: 'edit-work-center-group',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/EditWorkCenterGroup.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //New Work Center
    {
        path: '/new-work-center',
        name: '/new-work-center',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/NewWorkCenter.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //Edit Work Center
    {
        path: '/edit-work-center/:work_center_id',
        name: 'edit-work-center',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/EditWorkCenter.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //View Work Center
    {
        path: '/view-work-center/:work_center_id',
        name: 'view-work-center',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/ViewWorkCenter.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },

    //MACHINES
    {
        path: '/machines',
        name: '/machines',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/Machines.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    //New Machine
    {
        path: '/new-machine',
        name: '/new-machine',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/NewMachine.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //Edit Machine
    {
        path: '/edit-machine/:machine_id',
        name: 'edit-machine',
        component: () => import(/* webpackChunkName: "plants-work-centers" */'../Manufacturing/views/EditMachine.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },

    //UNITS OF MEASUREMENT
    //Units of Measurement Table
    {
        path: '/units-of-measurement',
        name: 'units-of-measurement',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/UnitsOfMeasurement.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    //New Unit of Measurement type
    {
        path: '/new-unit-of-measurement',
        name: 'new-unit-of-measurement',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/NewUnitOfMeasurement.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Unit of Measurement
        path: '/edit-unit-of-measurement/:unit_of_measurement_id',
        name: 'edit-unit-of-measurement',
        component: () => import(/* webpackChunkName: "materials" */'../Manufacturing/views/EditUnitOfMeasurement.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Work Orders Table
        path: '/work-orders',
        name: 'work-orders',
        component: () => import(/* webpackChunkName: "manufacturing" */'../Manufacturing/views/WorkOrders.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Work Order (From Sale)
        path: '/new-work-order/:order_id',
        name: 'new-work-order-sale',
        component: () => import(/* webpackChunkName: "manufacturing" */'../Manufacturing/views/NewWorkOrder_FromSale.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //New Work Order (From Stock)
        path: '/new-work-order-stock',
        name: 'new-work-order-stock',
        component: () => import(/* webpackChunkName: "manufacturing" */'../Manufacturing/views/NewWorkOrder_ForStock.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Work Order
        path: '/edit-work-order/:work_order_id',
        name: 'edit-work-order',
        component: () => import(/* webpackChunkName: "manufacturing" */'../Manufacturing/views/EditWorkOrder.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //View Work Order
        path: '/view-work-order/:work_order_id',
        name: 'view-work-order',
        component: () => import(/* webpackChunkName: "manufacturing" */'../Manufacturing/views/ViewWorkOrder.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //Work Order Schedule
        path: '/work-order-schedule',
        name: 'work-order-schedule',
        component: () => import(/* webpackChunkName: "manufacturing" */'../Manufacturing/views/WorkOrderSchedule.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]