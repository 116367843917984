const claim = {
    read: "work_orders_read",
    write: "work_orders_write"
}
export default [
    //Production Instructions
    {
        path: '/production-instructions',
        name: 'production-instructions',
        component: () => import(/* webpackChunkName: "production-instructions" */'../ProductionInstructions/views/ProductionInstructions.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        path: '/new-production-instructions',
        name: 'new-production-instructions',
        component: () => import(/* webpackChunkName: "production-instructions" */'../ProductionInstructions/views/NewProductionInstructions.vue'),
        //Requires a login to access
        meta: { requiresAuth: true, customClaim: claim.read }
    },
]