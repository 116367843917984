const claim = {
    read: "employees_read",
    write: "employees_write"
}
export default [
    //EMPLOYEES
    {
        //Employees Table
        path: '/employees',
        name: 'employees',
        component: () => import(/* webpackChunkName: "employees" */'../Employees/views/Employees.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Employee
        path: '/new-employee',
        name: 'new-employee',
        component: () => import(/* webpackChunkName: "employees" */'../Employees/views/NewEmployee.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Employee
        path: '/edit-employee/:employee_id',
        name: 'edit-employee',
        component: () => import(/* webpackChunkName: "employees" */'../Employees/views/EditEmployee.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    //-------------------------------------------------------------
    //JOB DESCRIPTIONS
    {
        //Job Description Table
        path: '/job-descriptions',
        name: 'job-descriptions',
        component: () => import(/* webpackChunkName: "job-descriptions" */'../Employees/views/JobDescriptions.vue'),
        meta: { requiresAuth: true, customClaim: claim.read }
    },
    {
        //New Job Description
        path: '/new-job-description',
        name: 'new-job-description',
        component: () => import(/* webpackChunkName: "job-descriptions" */'../Employees/views/NewJobDescription.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
    {
        //Edit Job Description
        path: '/edit-job-description/:job_description_id',
        name: 'edit-job-description',
        component: () => import(/* webpackChunkName: "job-descriptions" */'../Employees/views/EditJobDescription.vue'),
        meta: { requiresAuth: true, customClaim: claim.write }
    },
]