<template>
    <div>
    <v-bottom-navigation
        v-if="isLoggedIn"
        grow
        background-color="#141442"
        dark
      >

    <v-btn
      to="/sales-orders"
    >
      <span>Sales</span>
      <v-icon>mdi-clipboard-multiple</v-icon>
    </v-btn>

    <v-btn to="/work-orders">
      <span>Manufacturing</span>
      <v-icon>mdi-hammer-wrench</v-icon>
    </v-btn>

    <v-btn to="/deliveries">
      <span>Deliveries</span>
      <v-icon>mdi-truck</v-icon>
    </v-btn>
  </v-bottom-navigation>
   </div>
</template>

<script>
import firebase from 'firebase'
export default {
    name: 'TheBottomNav',
    data(){
      return {
        //Used to hide or display certain components based on login status
        isLoggedIn: false,
        currentUser: false
      }
    },
    created(){
      //Tests to see if user is logged in
      if(firebase.auth().currentUser){
        this.isLoggedIn = true
        this.currentUser = firebase.auth().currentUser.user_email
      }
    },
    methods: {
      //Logs out the current user
      userLogout: function(){
        firebase.auth().signOut().then(() => {
          this.$router.push('/login')
        })
      }
    },
    computed: {
    currentRouteName() {
        return this.$route.name;
    }
}
}
</script>